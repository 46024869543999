import React from 'react'
import PromotionTemplate from 'templates/promotion-template'
import { graphql } from 'gatsby'

const Promotion = props => {
  return <PromotionTemplate {...props} />
}

export const pageQuery = graphql`
  query promotionSingleQuery($id: String!, $tags: [String!]) {
    hutsonPromotion(id: { eq: $id }) {
      deereProductList {
        image
        imageAlt
        name
        overview
        sku
        slug
      }
      endDate
      endDateString
      excerpt
      html
      id
      imageSrc: image
      localImage: image
      productList {
        product
        type
      }
      slug
      stihlProductList {
        description
        firstImage {
          asset {
            gatsbyImageData(layout: FIXED, width: 640)
          }
        }
        slug
        sku
        title
      }
      tags
      title
    }
    allHutsonPromotion(filter: { tags: { in: $tags } }, sort: { fields: [endDate], order: ASC }) {
      nodes {
        endDateString
        id
        image
        link
        slug
        tags
        title
      }
    }
  }
`

export default Promotion
